<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <!-- <feather-icon
        badge="0"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      /> -->
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <b-badge pill variant="light-primary"> 0 New </b-badge>
      </div>
    </li>

    <!-- <vue-perfect-scrollbar
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-link
        v-for="notification in notifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.type"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>

      <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          System Notifications
        </h6>
        <b-form-checkbox
          :checked="true"
          switch
        />
      </div>

      <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar> -->

    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        >Read all notifications</b-button
      >
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox
  },
  directives: {
    Ripple
  },
  data() {
    return {
      registrationToken: ''
    }
  },
  setup() {
    const { watchNotification } = useAppConfig()

    /* eslint-disable global-require */
    const notifications = [
      {
        title: 'Congratulation Sam 🎉',
        avatar: require('@/assets/images/avatars/6-small.png'),
        subtitle: 'Won the monthly best seller badge',
        type: 'light-success'
      },
      {
        title: 'New message received',
        avatar: require('@/assets/images/avatars/9-small.png'),
        subtitle: 'You have 10 unread messages',
        type: 'light-info'
      },
      {
        title: 'Revised Order 👋',
        avatar: 'MD',
        subtitle: 'MD Inc. order updated',
        type: 'light-danger'
      }
    ]
    /* eslint-disable global-require */

    const systemNotifications = [
      {
        title: 'Server down',
        subtitle: 'USA Server is down due to hight CPU usage',
        type: 'light-danger',
        icon: 'XIcon'
      },
      {
        title: 'High memory usage',
        subtitle: 'BLR Server using high memory',
        type: 'light-warning',
        icon: 'AlertTriangleIcon'
      }
    ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false
    }

    return {
      notifications,
      systemNotifications,
      perfectScrollbarSettings,
      watchNotification
    }
  },
  async mounted() {
    await this.listeningFirebase()
  },
  methods: {
    listeningFirebase() {
      try {
        this.$viFirebase
          .messaging()
          .requestPermission()
          .then(() => {
            this.errorRequest = false
            console.log('Notification permission granted')
            this.$viFirebase
              .messaging()
              .getToken()
              .then((token) => {
                this.$store.commit('visitor/UPDATE_TOKEN_NOTIFICATION', token)
                this.registrationToken = token
                console.log('registrationToken: ', token)

                this.subscribeToTopic(
                  token,
                  `${process.env.VUE_APP_TOPIC_VISITOR}`
                ) /* sal|land */

                // let admin =
                //   this.$can('manage', 'all') /* Host */ ||
                //   this.$can('manage', 'qrcode') /* Security */ ||
                //   this.$can('manage', 'special') /* Admin Agent */ ||
                //   this.$can('approvement', '') /* Admin PIL */

                // let landAccess =
                //   /* Published form */
                //   this.$can('manage', 'form1') ||
                //   this.$can('manage', 'form2') ||
                //   this.$can('manage', 'form3')
                // let riverAccess =
                //   /* Published form */
                //   this.$can('SAL', '') ||
                //   this.$can('TBBM', '') ||
                //   this.$can('TBBM JETTY 3A', '') ||
                //   this.$can('Hulu Migas', '')

                // if (admin || landAccess) {
                //   console.info(
                //     'subscribe to topic ::',
                //     process.env.VUE_APP_TOPIC_LAND
                //   )
                //   this.subscribeToTopic(
                //     token,
                //     `${process.env.VUE_APP_TOPIC_LAND}`
                //   ) /* sal|land */
                // }

                // if (admin || riverAccess) {
                //   console.info(
                //     'subscribe to topic ::',
                //     process.env.VUE_APP_TOPIC_RIVER
                //   )
                //   this.subscribeToTopic(
                //     token,
                //     `${process.env.VUE_APP_TOPIC_RIVER}`
                //   ) /* river */
                // }
              })
          })
          .catch((err) => {
            console.log('Unable to get token ', err)
            this.errorRequest = true
            // this.$parent.showAlert()
          })
      } catch (e) {
        console.log(e)
      }
    },
    async subscribeToTopic(token, topic) {
      await useJwt.http
        .post(`notification/subscribe/topic/${topic}/visitor`, {
          registrationToken: token
        })
        .then((success) => {
          console.log(success.data.message)
          this.errorRequest = false
          this.receiveMessage()
        })
        .catch((error) => {
          this.errorRequest = true
          console.log('error subscribe to topic', error)
          this.$parent.showAlert()
        })
    },
    async receiveMessage() {
      try {
        this.$viFirebase.messaging().onMessage((payload) => {
          // this.fetch()
          console.log('payload here', payload)

          const action = payload.data.action
          const tag = payload.data.tag
          const fcmMessageId = payload.fcmMessageId

          this.$alert({
            title: 'Notification',
            icon: 'InfoIcon',
            text: `${payload.notification.title}, ${payload.notification.body}`,
            varianty: 'primary'
          })

          if (tag === 'visitor') {
            console.log('visitor notification trigger')
            this.watchNotification = payload
          }
          // if (action.includes('Clock')) {
          //   this.$store.commit('app/UPDATE_WATCH_NOTIFICATION_CLOCK_IN', '')
          // } else {
          //   this.$store.commit('app/UPDATE_WATCH_NOTIFICATION', '')
          // }

          // console.log('tag notification::', tag)
          // if (tag === 'river_route') {
          //   this.watchNotificationRiver = payload
          // }

          // khusus approval limit
          // if (action.includes(' ( ✓ Approved )')) {
          //   console.log('Approved::')
          //   this.watchApproval = fcmMessageId
          //   console.log('Approved::', this.watchApproval)
          // }
          // if (action.includes(' ( ✓ Clock OUT )')) {
          //   console.log('Clock OUT::')
          //   this.watchApproval = fcmMessageId
          //   console.log('Clock OUT::', this.watchApproval)
          // }
          // if (action.includes(' ( 𝕩 Cancels Approved )')) {
          //   console.log('Cancels Approved::')
          //   this.watchApproval = fcmMessageId
          //   console.log('Cancels Approved::', this.watchApproval)
          // }
        })
      } catch (e) {
        console.log(e)
        this.errorRequest = true
        this.$parent.showAlert()
      }
    }
  }
}
</script>

<style></style>
