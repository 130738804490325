export default [
  {
    header: 'SuperAdmin Interface',
    acl: [
      {
        resource: 'user',
        action: 'manage'
      },
      {
        resource: 'all',
        action: 'manage'
      }
    ]
  },
  {
    title: 'Account',
    route: 'sa-users',
    icon: 'UserIcon',
    acl: [
      {
        resource: 'user',
        action: 'manage'
      },
      {
        resource: 'all',
        action: 'manage'
      }
    ]
  },
  {
    header: 'Monitoring Analytic'
    // acl: myACL
  },
  {
    title: 'Planning',
    route: 'analytic-planning',
    icon: 'CalendarIcon'
    // acl: myACL
  },
  {
    title: 'IN Area',
    route: 'analytic-inarea',
    icon: 'LinkedinIcon'
    // acl: myACL
  },
  {
    title: 'OUT Area',
    route: 'analytic-outarea',
    icon: 'LogOutIcon'
    // acl: myACL
  },
  {
    header: 'QRCode Planning'
    // acl: myACL
  },
  {
    title: 'Create Form Umum',
    route: 'create-form-umum',
    icon: 'BookOpenIcon'
    // acl: myACL
  },
  {
    title: 'Reuse QR',
    route: 'reuse-qrcode',
    icon: 'RepeatIcon'
    // acl: myACL
  },
  // {
  //   header: 'Identify'
  //   // acl: myACL
  // },
  // {
  //   title: 'My Data',
  //   route: 'my-data',
  //   icon: 'FileIcon'
  //   // acl: myACL
  // },
  {
    header: 'Special Access'
    // acl: [
    //   {
    //     resource: '',
    //     action: 'create-form-khusus'
    //   },
    //   {
    //     resource: 'all',
    //     action: 'manage'
    //   }
    // ]
  },
  {
    title: 'Create Form Khusus',
    route: 'create-form-khusus',
    icon: 'UsersIcon'
    // acl: [
    //   {
    //     resource: '',
    //     action: 'create-form-khusus'
    //   },
    //   {
    //     resource: 'all',
    //     action: 'manage'
    //   }
    // ]
  },
  {
    header: 'QRCode Controll',
    acl: [
      {
        resource: '',
        action: 'visitor-umum'
      },
      {
        resource: '',
        action: 'visitor-khusus'
      },
      {
        resource: '',
        action: 'create-form-khusus'
      },
      {
        resource: 'all',
        action: 'manage'
      }
    ]
  },
  {
    title: 'Visitor Umum',
    route: 'visitor-umum',
    icon: 'UnlockIcon',
    acl: [
      {
        resource: '',
        action: 'visitor-umum'
      },
      {
        resource: 'all',
        action: 'manage'
      }
    ]
  },
  {
    title: 'Visitor Khusus',
    route: 'visitor-khusus',
    icon: 'LockIcon',
    acl: [
      {
        resource: '',
        action: 'create-form-khusus'
      },
      {
        resource: '',
        action: 'visitor-khusus'
      },
      {
        resource: 'all',
        action: 'manage'
      }
    ]
  },
  {
    header: 'Data Reporting',
    acl: [
      {
        resource: '',
        action: 'reporting'
      },
      {
        resource: 'all',
        action: 'manage'
      }
    ]
  },
  {
    title: 'Reporting',
    route: 'reporting-visitor',
    icon: 'PrinterIcon',
    acl: [
      {
        resource: '',
        action: 'reporting'
      },
      {
        resource: 'all',
        action: 'manage'
      }
    ]
  }
]
