<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <small>
        © 2024 - {{ new Date().getFullYear() }}
        <a
          href="https://github.com/yogithesymbian"
          target="_blank"
          rel="noopener noreferrer"
          style="z-index: 999"
        >
          Yogi Arif Widodo
        </a>
        🎸
        <a
          href="https://landing.salpalaran.com/"
          target="_blank"
          rel="noopener noreferrer"
          style="z-index: 999"
        >
          PT Sarana Abadi Lestari
        </a>
      </small>
    </span>
    <span class="float-md-right d-none d-md-block"
      >Visitor
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink
  }
}
</script>
